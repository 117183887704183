import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const DynamicLessonPage = () => {
  const { sectionId, lessonId } = useParams();
  const [LessonComponent, setLessonComponent] = useState(null);

  useEffect(() => {
    const fetchLessonComponent = async () => {
      try {
        const lessonModule = await import(`./section${sectionId}/${lessonId}`);
        setLessonComponent(() => lessonModule.default);
      } catch (error) {
        console.error("Could not load lesson component", error);
      }
    };

    fetchLessonComponent();
  }, [sectionId, lessonId]);

  return (
    <div>
      {LessonComponent ? <LessonComponent /> : <div>Lesson not found</div>}
    </div>
  );
};

export default DynamicLessonPage;