import React from 'react'
import '../styles/tailwind.css';
import { useAuth } from '../hooks/useAuth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { app } from '../firebase'; 

const db = getFirestore(app);

const LandingPage = () => {

    const { isLoggedIn, user} = useAuth(); // Get the current user

    const handleEnroll = async () => {
      if (!isLoggedIn) {
        
        alert('You need to be logged in to enroll.');
        return;
      }
  
      const uid = user.uid;
  
      try {
        console.log(uid);
        await setDoc(doc(db, 'users', uid), {
          enrolledCourses: {
            webDevelopmentBootcamp: true,
          },
        }, { merge: true });

        //make sure to navigate to home where it will show the course.
    
        alert('You have been enrolled in the Web Development Bootcamp!');
      } catch (error) {
        console.error('Error enrolling in course:', error.message);
        console.error('Error details:', error);
        alert('Error enrolling in course. Please try again.');
      }

      
    };


    return (
        <div className="flex flex-col md:flex-row justify-between items-start p-10 font-inter mx-10 md:mx-36">
            <div className="md:w-2/3">
                {/* <span className="inline-block bg-orange-100 text-orange-500 font-bold rounded-full px-3 py-1 text-sm mb-4">Job Guarantee</span> */}
                <h1 className="text-4xl sm:text-5xl md:text-6xl leading-tight mt-16 mb-8">
                Just 15 minutes a day to Master Web Development.
                  
                    {/* Learn Web Development with Guaranteed Jobs Monthly. */}
                </h1>
                <p className="text-black">   Become a Web Developer and Complete Guaranteed Freelance Jobs monthly.<br></br> Bite Sized, Guided, Interactive Lessons with AI.</p>
                <div className=" mt-12 p-6">
                    <hr className="border-t border-gray-300 mb-6" />
                    <h2 className="text-xl font-bold mb-4">At a Glance</h2>
                    <ul className="grid grid-cols-1 md:grid-cols-2 gap-3 text-base"> 
                        <li className="flex items-start max-w-md">
                            <span className="mr-2">✓</span>
                            Build 16 web development projects for your portfolio, ready to apply for junior developer jobs.
                        </li>
                        <li className="flex items-start max-w-md">
                            <span className="mr-2">✓</span>
                            Learn the latest technologies, including Javascript, React, Node and even Web3 development.
                        </li>
                        <li className="flex items-start max-w-md">
                            <span className="mr-2">✓</span>
                            After the course you will be able to build ANY website you want.
                        </li>
                        <li className="flex items-start max-w-md">
                            <span className="mr-2">✓</span>
                            Build fully-fledged websites and web apps for your startup or business.
                        </li>
                        <li className="flex items-start max-w-md">
                            <span className="mr-2">✓</span>
                            Work as a freelance web developer.
                        </li>
                        <li className="flex items-start max-w-md">
                            <span className="mr-2">✓</span>
                            Master frontend development with React.
                        </li>
                        <li className="flex items-start max-w-md">
                            <span className="mr-2">✓</span>
                            Master backend development with Node.
                        </li>
                        <li className="flex items-start max-w-md">
                            <span className="mr-2">✓</span>
                            Learn professional developer best practices.
                        </li>
                    </ul>
                    <hr className="border-t border-gray-300 mt-8" />
                </div>
            </div>

            <div className="border-2 border-black p-10 rounded-lg shadow-lg max-w-xs w-full mt-20 md:mt-10">
    <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold mb-4">
        Ready to launch your software engineering career?
    </h2>
    {/* <p className="text-xl mb-4">Try 7 day free trial and then <strong>$20 / month</strong></p> */}
    <div className="mb-6">
        <label htmlFor="plan" className="block text-lg font-medium mb-1">Solo</label>
        <select id="plan" className="block w-full mt-1 p-2 border border-gray-300 rounded-md">
            <option>Solo</option>
            <option>Group</option>
        </select>
    </div>
    <button onClick={handleEnroll} className="w-full bg-purple-600 text-white font-bold py-2 px-4 rounded-md hover:bg-purple-700">
        Try For Free.
    </button>
    <p className="mt-4 text-gray-600">1,978 enrolled</p>
</div>

        </div>
    );
}

export default LandingPage