import React, { useState, memo } from 'react';
import { Link } from 'react-router-dom';

const Section = memo(({ title, description, lectures, userId, link }) => {
  const [updatedLectures, setUpdatedLectures] = useState(lectures);

  const handleCompletionToggle = (lectureTitle, completed) => {
    setUpdatedLectures(prevLectures =>
      prevLectures.map(lecture =>
        lecture.title === lectureTitle ? { ...lecture, completed } : lecture
      )
    );
  };

  const completedLessons = updatedLectures.filter(lecture => lecture.completed).length;
  const completionPercentage = (completedLessons / updatedLectures.length) * 100;

  return (
    <div className="max-w-lg mx-auto my-4">
      <div className={`bg-[#E6F3FF] rounded-lg shadow-lg overflow-hidden `} style={{ height: '300px' }}>
        <div className="px-6 py-4 " style={{ marginTop: '20px' }}>
          <div className="font-bold text-xl mb-2 text-gray-800 mt-4" style={{ fontSize: '24px' }}>
            {title}
          </div>
          <div className="progress-bar-container border border-black" style={{ backgroundColor: 'white', borderRadius: '10px', marginBottom: '20px', width: '50%' }}>
            <div 
              className="progress-liquid" 
              style={{ 
                width: `${completionPercentage}%`, 
                backgroundColor: '#228B22',
                height: '15px', 
                borderRadius: '7px', 
                transition: 'width 0.5s ease-in-out', 
                border: completionPercentage > 0 ? '1px solid black' : 'none' 
              }}
            ></div>
          </div>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-indigo-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {completedLessons}/{updatedLectures.length} Completed
          </span>
        </div>
        <Link to={link} className="w-full text-left ml-6">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
            Continue
          </button>
        </Link>
      </div>
    </div>
  );
});

export default Section;