import React, { useState } from 'react';
import ContentCard from './ContentCard';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


//@params 
//lectures is an array that holds objects that represent a lesson 


const Dropdown = ({ title, description, lectures, userId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [updatedLectures, setUpdatedLectures] = useState(lectures);

  const toggleDropdown = () => setIsVisible(!isVisible);

  const handleCompletionToggle = (lectureTitle, completed) => {
    setUpdatedLectures(prevLectures =>
      prevLectures.map(lecture =>
        lecture.title === lectureTitle ? { ...lecture, completed } : lecture
      )
    );
  };

  const completedLessons = updatedLectures.filter(lecture => lecture.completed).length;
  const completionPercentage = (completedLessons / updatedLectures.length) * 100;





  return (
    <div className="max-w-lg mx-auto my-8">


       {isVisible && (
        
     
     <div className="px-6 pt-4 pb-2">
      
       {updatedLectures.map((lecture) => (
         <ContentCard
           key={lecture.title}
           title={lecture.title}
           description={lecture.description}
           link={lecture.link}
           userId={userId}
           isCompleted={lecture.completed}
           onCompletionToggle={handleCompletionToggle}
         />
       ))}

     </div>
   )}

   {!isVisible &&  (
    <div className={`bg-white rounded-lg shadow-lg overflow-hidden ${isVisible ? 'ring-2 ring-indigo-500' : 'ring-1 ring-gray-300'} transition-all duration-300 ease-in-out`}>
        
    <button onClick={toggleDropdown} className="w-full text-left">
    
      <div className="px-6 py-4" style={{marginTop: '20px'}}>
        <div className="font-bold text-xl mb-2 text-gray-800" style={{fontSize: '24px'}}>
          {title}
        </div>
        <p className="text-gray-700 text-base">{description}</p>
      </div>
      <div className="px-6 pt-4 pb-2">
        <span className="inline-block bg-indigo-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          {completedLessons}/{updatedLectures.length} Completed
        </span>
        <div className="progress-bar-container" style={{backgroundColor: '#0a0a0a', borderRadius: '10px', padding: '3px', marginBottom: '20px'}}>
          <div className="progress-liquid" style={{width: `${completionPercentage}%`, backgroundColor: '#4b0082', height: '20px', borderRadius: '7px', transition: 'width 0.5s ease-in-out', background: 'linear-gradient(to right, #0a0a0a, #4b0082)'}}></div>
        </div>
      </div>
    </button>

  </div>
   )}
      
     

      
    </div>
  );
};

export default Dropdown;