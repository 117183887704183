import React, { useState,useRef, useEffect } from 'react';
import Dropdown from '../../coursecomponents/Dropdown';
import { db } from '../../firebase';
import { collection, addDoc, doc, setDoc, getDoc, updateDoc} from 'firebase/firestore';
import { useAuth } from '../../hooks/useAuth';

import DailyQuests from './DailyQuest';
import { useEnhancedCourseData } from '../sat/data/data.js'




const BootcampHome = () => {
  const enhancedCourseData1 = useEnhancedCourseData();
  const { user } = useAuth(); // Use the useAuth hook to get the current user
  const userId = user ? user.uid : null; // Access the uid property from the user object


  //3 objects 






  


   return (
     <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1.5fr', gap: '30px' }}>
   
      <div>Column 1 content</div>
      <div>
        {enhancedCourseData1.map((sectionData) => (
          Array.isArray(sectionData.lectures) && (
            <Dropdown
              title={sectionData.title}
              description={sectionData.description}
              lectures={sectionData.lectures}
              userId={userId} // Pass userId as a prop
            />
          )
        ))}
    
       
        
      </div>
      {/* place the new component right here */}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DailyQuests />
      <p>leaderboards</p>
    </div>


    </div>
     );
    };
    

export default BootcampHome;