import React, { useState } from 'react';
import { auth, db, app } from '../firebase';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const auth = getAuth(app);

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        displayName: name,
        boughtCourse: true,
        xp: 0,
        streak: 0, // Initialize streak
        lastCompleted: null, // Initialize lastCompleted
      });

      navigate('/sat'); // Redirect to the enrolled page after successful sign-up
    } catch (error) {
      console.error(error);
      alert('Error signing up. Please try again.');
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form className="bg-white p-8 rounded-lg shadow-md max-w-md w-full" onSubmit={handleSignUp}>
        <h2 className="text-2xl font-bold mb-6 text-center">Sign up and start learning</h2>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Full name"
            className="w-full p-3 border border-gray-300 rounded-lg"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            placeholder="Email"
            className="w-full p-3 border border-gray-300 rounded-lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            placeholder="Password"
            className="w-full p-3 border border-gray-300 rounded-lg"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="flex items-center mb-6">
          <input type="checkbox" className="h-4 w-4 text-purple-600" />
          <label className="ml-2 text-sm text-gray-600">Send me special offers, personalized recommendations, and learning tips.</label>
        </div>
        <button type="submit" className="w-full bg-purple-600 text-white py-3 rounded-lg font-bold">Sign up</button>
        <p className="mt-4 text-center text-sm text-gray-600">
          By signing up, you agree to our <a href="#" className="text-purple-600">Terms of Use</a> and <a href="#" className="text-purple-600">Privacy Policy</a>.
        </p>
        <p className="mt-4 text-center text-sm text-gray-600">
          Already have an account? <a href="/signin" className="text-purple-600">Log in</a>
        </p>
      </form>
    </div>
  );
};

export default SignUp;