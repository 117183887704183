import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, orderBy('xp', 'desc'), limit(10));
        const querySnapshot = await getDocs(q);
        
        const leaderboardData = querySnapshot.docs.map((doc, index) => {
          const data = doc.data();
          return {
            uid: doc.id,
            displayName: data.displayName || `User ${index + 1}`,
            xp: data.xp || 0,
          };
        });

        console.log('Leaderboard data:', leaderboardData); // For debugging
        setLeaderboard(leaderboardData);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };

    fetchLeaderboard();
  }, []);

  return (
    <div className="leaderboard w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden mt-4">
      <h2 className="text-2xl font-bold text-center py-4">Leaderboard</h2>
      <ul className="divide-y divide-gray-200">
        {leaderboard.map((userScore, index) => (
          <li key={userScore.uid} className={`flex items-center justify-between p-4 ${userScore.uid === user?.uid ? 'bg-gray-100' : ''}`}>
            <div className="flex items-center px-4">
              <span className={`text-xl font-bold mr-4 ${index < 3 ? 'text-green-500' : 'text-gray-500'}`}>
                {index + 1}
              </span>
              <span className="text-lg font-medium">{userScore.displayName}</span>
            </div>
            <span className="text-lg font-semibold px-4">{userScore.xp} XP</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Leaderboard;