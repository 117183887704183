import React from 'react';
import { useState } from 'react';
import { db } from '../firebase'; // Import Firebase database reference
import { useNavigate } from 'react-router-dom';

const courseData = {
  title: 'Web Development Full Bootcamp',
  description: 'Learn how to access AWS services securely using PrivateLink.',
  image: 'course1.jpg', // Replace with actual image URL
  ce: 10,
  level: 'Intermediate',
  duration: '2hr',
};

function DiscoverBootcamp() {
  const [isAdding, setIsAdding] = useState(false);
  const navigate = useNavigate(); // Get the navigate function

  const handleAddCourse = async () => {
    setIsAdding(true);

    try {
      // Assuming you're adding course data to Firebase or performing some operation
      // Example: await db.collection('courses').add(courseData);

      // Navigate to /learnbootcamp after adding the course
      navigate('/learnbootcamp');
    } catch (error) {
      console.error('Error adding course:', error);
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <div className="bg-gray-800 rounded-lg overflow-hidden shadow-md w-1/2">
      {/* <img src={courseData.image} alt={courseData.title} className="w-full h-48 object-cover" /> */}
      <div className="p-4">
        <h2 className="text-2xl font-bold text-white">{courseData.title}</h2>
        <p className="text-gray-400">{courseData.description}</p>
        <div className="flex justify-between items-center mt-4">
          <p className="text-gray-300">CE {courseData.ce}: {courseData.level} • {courseData.duration}</p>
          <button
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${isAdding ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleAddCourse}
            disabled={isAdding}
          >
            {isAdding ? 'Adding...' : 'Get Started'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DiscoverBootcamp;
