import React from 'react';

const InfoCardReverse = ({ title, description, image }) => {
  return (
    <div class="bg-white">
    

    <section class="bg-[#FCF8F1] bg-opacity-30 py-10 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
            <div>
                    <img class="w-full" src={image} alt="" />
                </div>
                <div>
                <h1 className="mt-4 text-2xl font-bold text-black lg:mt-8 sm:text-4xl xl:text-6xl">{title}</h1>
                    <p class="mt-4 text-base text-black lg:mt-8 sm:text-xl">{description}</p>

                

                  
                </div>

               
            </div>
        </div>
    </section>
</div>

  );
};

export default InfoCardReverse;