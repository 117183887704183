import React from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

const AboutSat = () => {
  return (
    <div className="mt-12"> {/* Added top margin to the container */}
      <section className="relative py-12 bg-white sm:py-16 lg:py-20">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="https://landingfoliocom.imgix.net/store/collection/clarity-blog/images/hero/5/grid-pattern.png"
            alt=""
          />
        </div>

        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-xl">
          <div className="max-w-xl mx-auto text-center">
            <h1 className="text-3xl font-bold text-gray-900 sm:text-4xl lg:text-5xl">
              Learn 15 min/day and get a 1400+ on the SAT, Guaranteed.
           
            </h1>
            <p className="max-w-md mx-auto mt-6 text-base font-normal leading-7 text-gray-500">
              Replace your time doom scrolling with fun bite sized, guided, and interactive lessons 
             to get into your dream college.
            </p>

            <form
              action="#"
              method="POST"
              className="max-w-md mx-auto mt-8 space-y-4 sm:space-y-0 sm:flex sm:justify-center"
            >
              <div className="relative group">
                <div className="absolute transition-all duration-1000 opacity-70 inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg filter group-hover:opacity-100 group-hover:duration-200"></div>

                <Link
                  to="/signup"
                  className="inline-flex relative items-center justify-center w-full sm:w-auto px-8 py-3 sm:text-sm text-base sm:py-3.5 font-semibold text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                >
                  Get Started Today
                </Link>
              </div>
            </form>

            <ul className="flex items-center justify-center mt-6 space-x-6 sm:space-x-8">
              <li className="flex items-center">
                <svg
                  className="w-5 h-5 mr-2 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                <span className="text-xs font-medium text-gray-900 sm:text-sm"> beta testing</span>
              </li>

              <li className="flex items-center">
                <svg
                  className="w-5 h-5 mr-2 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                <span className="text-xs font-medium text-gray-900 sm:text-sm"> Join other 1600+ pre-waitlist </span>
              </li>
            </ul>
       

            
          </div>
               <hr className="my-8 border-t border-gray-300" />
                     <Banner/>
        </div>
      </section>

     <section className="py-12 bg-white sm:py-16 lg:py-20">
<div className="px-4 mx-auto max-w-5xl sm:px-6 lg:px-8">
      <div className="text-center">
      <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl xl:text-5xl">All for Free.</h2>
      <p className="text-base leading-7 text-gray-600 sm:mt-8">Whaaat</p>
    </div>

    <div className="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-3 md:gap-0 xl:mt-24">
      <div className="md:p-8 lg:p-14">
        <h3 className="text-xl font-bold text-gray-900">Fun Guarantee</h3>
        <p className="text-base text-gray-600">Our content is similar to Duolingo with gamified features to make learning as fun as possible.</p>
      </div>

      <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200">
    
        <h3 className="text-xl font-bold text-gray-900">Personalized, Interactive, and Bite Sized</h3>
        <p className="mt-5 text-base text-gray-600">Tailored learning experiences just for you.</p>
      </div>

      <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200">
    
        <h3 className="mt-6 text-xl font-bold text-gray-900">100+ Lessons</h3>
        <p className="mt-5 text-base text-gray-600">Comprehensive lessons covering all SAT topics.</p>
      </div>

      <div className="md:p-8 lg:p-14 md:border-t md:border-gray-200">
       
        <h3 className="mt-12 text-xl font-bold text-gray-900">Unlimited Tests</h3>
        <p className="mt-5 text-base text-gray-600">Practice as much as you need with unlimited tests.</p>
      </div>

      <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t">
      
        <h3 className="mt-12 text-xl font-bold text-gray-900">AI Assistance</h3>
        <p className="mt-5 text-base text-gray-600">Get personalized help to learn material effectively.</p>
      </div>

      <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t">
     
        <h3 className="mt-12 text-xl font-bold text-gray-900">Live Classes Weekly</h3>
        <p className="mt-5 text-base text-gray-600">Learn from the top talent.</p>
      </div>
    </div>
  </div>
</section>



      <Footer/>

    </div>
    
  );
};

export default AboutSat;