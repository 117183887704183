// src/components/Banner.js
import React from 'react';

const Banner = () => {
  return (
    <div className="bg-white-100 py-8">
      <div className="container mx-auto text-center">
        <p className="text-gray-600 text-lg mb-4">
        
        </p>
        <div className="flex justify-center items-center space-x-8">
          <img src="/emory.png" alt="Emory" className="h-10" />
          <img src="/stanford.png" alt="MIT" className="h-12" />
           <img src="/georgiatech.png" alt="MIT" className="h-12" />
       
          {/* <span className="text-gray-600 text-lg">AND MANY MORE...</span> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;