import React from 'react';

const Resume = () => {
  return (
    <div className="overflow-x-hidden ">
      <section className="pt-12 sm:pt-16">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h1 className="px-6 text-lg text-gray-600 font-inter">Created by Web Developers for Web Developers</h1>
            <p className="mt-5 text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-6xl lg:leading-tight font-pj">
              Your New Resume.
              <span className="relative inline-flex sm:inline">
                <span className="bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] blur-lg filter opacity-30 w-full h-full absolute inset-0"></span>
                {/* <span className="relative"> Resume. </span> */}
              </span>
            </p>
            <p className="mt-8 text-base text-black-500 font-inter">See below all languages taught, potential projects, job types.</p>
          </div>
        </div>
      </section>

      

      <section className="pb-12 bg-white">
        <div className="relative mx-auto flex justify-center items-center h-auto pt-12"> {/* Add padding-top */}
          <div className="lg:max-w-6xl lg:mx-auto">
            <img
              className="transform scale-110 h-[32rem] object-contain" // Adjust the height here
              src="https://preview.redd.it/man-its-a-brutal-software-engineering-market-right-now-this-v0-ygw66h3pzahb1.png?width=1080&crop=smart&auto=webp&s=cbf6509ff1ee908f7b6906e6f5a9408ddfbda3c1"
              alt=""
            />
          </div>
        </div>
      </section>

      <hr className="border-gray-300 my-8" />
    </div>

    
  );
};

export default Resume;