import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, LayoutDashboard, XCircle, Search, Bell, User, ShoppingBag } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({ isCollapsed, toggleSidebar }) => {
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname);

  const handleItemClick = (path) => {
    setSelected(path);
  };

  const sidebarClass = isCollapsed ? 'w-16' : 'w-64';

  const menuItems = [
    { icon: LayoutDashboard, label: "Home", path: "/sat/home" },
    { icon: XCircle, label: "Jobs", path: "/sat/jobs" },
    { icon: Bell, label: "Leaderboards", path: "/sat/leaderboards" },
    { icon: XCircle, label: "Quests", path: "/sat/quests" },
    { icon: Search, label: "AIVA", path: "/sat/aiva" },
    { icon: ShoppingBag, label: "Store", path: "/sat/store" },
    { icon: User, label: "Profile", path: "/sat/profile" },
  ];

  return (
    <div className={`bg-white border-r border-gray-200 h-screen transition-all duration-300 ${sidebarClass}`}>
      <div className="p-4">
        <div className="flex items-center justify-end">
          <button 
            onClick={toggleSidebar} 
            className="p-1 rounded-full hover:bg-gray-100 text-gray-600"
            aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
          >
            {isCollapsed ? <ChevronRight size={24} /> : <ChevronLeft size={24} />}
          </button>
        </div>
      </div>
      <nav className="mt-4">
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            onClick={() => handleItemClick(item.path)}
            className={`flex items-center p-4 pl-8 text-gray-600 hover:bg-gray-100 hover:text-blue-600 transition-colors duration-200 ${
              selected === item.path ? 'border-2 border-blue-500 bg-blue-100' : ''
            }`}
          >
            <item.icon size={20} className="mr-4" />
            {!isCollapsed && <span>{item.label}</span>}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;