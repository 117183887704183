import React, { useState } from 'react';
import Leaderboard from '../../components/Leaderboard';
import Sidebar from '../sat/components/Sidebar';

const LeaderboardHome = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="flex">
      <Sidebar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      <div className="flex-grow p-4">
        <h1 className="text-3xl font-bold mb-4">Leaderboard</h1>
        <Leaderboard />
      </div>
    </div>
  );
};

export default LeaderboardHome;