// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAuth } from "firebase/auth"; // Import the Firebase Auth library

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBjnZShz82yujK_557QTkTmP7VtLaN0ErI",
  authDomain: "avantdemy-8493e.firebaseapp.com",
  projectId: "avantdemy-8493e",
  storageBucket: "avantdemy-8493e.appspot.com",
  messagingSenderId: "1067395231310",
  appId: "1:1067395231310:web:efbfb45675cadccd249d1f",
  measurementId: "G-HS2GYPBH6K"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize Firebase Auth
const db = getFirestore(app); // Initialize Firestore

export { auth, db, app }; // Export the Firestore instance
