import React from 'react';

const DailyQuests = () => {
  return (
<>
<div className="p-4 mt-8 bg-white rounded-lg border border-black" style={{ width: '70%', height: '250px'  }}>      
  <h2 className="text-lg font-semibold mb-4">Daily Quests</h2>
      <div className="space-y-4">

        
        {/* Earn 10 XP */}
        <div className="flex items-center">
          <div className="flex items-center justify-center w-8 h-8 bg-yellow-400 rounded-full">
            {/* Replace with an appropriate icon */}
            <span className="text-white">⚡</span>
          </div>
          <div className="flex-1 ml-4">
            <div className="text-sm font-medium">Earn 10 XP</div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-1">
              <div className="bg-yellow-400 h-2.5 rounded-full" style={{ width: '0%' }}></div>
            </div>
          </div>
          <div className="ml-4 w-8 h-8 bg-brown-500 rounded-full flex items-center justify-center">
            {/* Replace with a lock icon */}
            <span>🔒</span>
          </div>
        </div>

        {/* Spend 5 minutes learning */}
        <div className="flex items-center">
          <div className="flex items-center justify-center w-8 h-8 bg-blue-400 rounded-full">
            {/* Replace with an appropriate icon */}
            <span className="text-white">⏱</span>
          </div>
          <div className="flex-1 ml-4">
            <div className="text-sm font-medium">Spend 5 minutes learning</div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-1">
              <div className="bg-blue-400 h-2.5 rounded-full" style={{ width: '0%' }}></div>
            </div>
          </div>
          <div className="ml-4 w-8 h-8 bg-brown-500 rounded-full flex items-center justify-center">
            {/* Replace with a lock icon */}
            <span>🔒</span>
          </div>
        </div>

        {/* Complete 3 perfect lessons */}
        <div className="flex items-center">
          <div className="flex items-center justify-center w-8 h-8 bg-green-400 rounded-full">
            {/* Replace with an appropriate icon */}
            <span className="text-white">🎯</span>
          </div>
          <div className="flex-1 ml-4">
            <div className="text-sm font-medium">Complete 3 perfect lessons</div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-1">
              <div className="bg-green-400 h-2.5 rounded-full" style={{ width: '0%' }}></div>
            </div>
          </div>
          <div className="ml-4 w-8 h-8 bg-brown-500 rounded-full flex items-center justify-center">
            {/* Replace with a lock icon */}
            <span>🔒</span>
          </div>
        </div>


        
      </div>
    </div>

  
</>
  );
};

export default DailyQuests;
