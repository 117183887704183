// src/contexts/UserProgressContext.js
import React, { createContext, useState, useContext } from 'react';

const UserProgressContext = createContext();

export const useUserProgress = () => useContext(UserProgressContext);

export const UserProgressProvider = ({ children }) => {
  const [userProgress, setUserProgress] = useState(null);

  return (
    <UserProgressContext.Provider value={{ userProgress, setUserProgress }}>
      {children}
    </UserProgressContext.Provider>
  );
};