// src/chapters/DynamicPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const DynamicPage = () => {
  const { chapterName, lessonName } = useParams();
  const [PageComponent, setPageComponent] = useState(null);

  useEffect(() => {
    const fetchPageComponent = async () => {
      try {
        const pageModule = await import(`/${chapterName}/${lessonName}`);
        setPageComponent(() => pageModule.default);
      } catch (error) {
        console.error("Could not load page component", error);
      }
    };

    fetchPageComponent();
  }, [chapterName, lessonName]);

  return (
    <div>
      {PageComponent ? <PageComponent /> : <div>Page not found</div>}
    </div>
  );
};

export default DynamicPage;