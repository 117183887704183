import React from 'react'

import DiscoverBootcamp from '../coursecomponents/DiscoverBootcamp'



const Library = () => {

    
  return (
    <div className="flex justify-center items-center mt-8">
      <DiscoverBootcamp />
    </div>
  )
}

export default Library