import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app } from '../../firebase'; 
import { useAuth } from '../../hooks/useAuth'; // Import the custom hook
import EnrolledCourse from './EnrolledCourse'; // Assuming you have this component

const db = getFirestore(app);

const Enrolled = () => {
  const { isLoggedIn, user } = useAuth(); // Get the current user

  const [isEnrolled, setIsEnrolled] = useState(false);

  useEffect(() => {
    if (user) {
      const uid = user.uid;

      const checkEnrollment = async () => {
        try {
          const userDocRef = doc(db, 'users', uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const data = userDoc.data();
            setIsEnrolled(data.enrolledCourses?.webDevelopmentBootcamp || false);
            console.log('Enrollment status checked successfully');
          }
        } catch (error) {
          console.error('Error checking enrollment status:', error);
        }
      };

      checkEnrollment();
    }
  }, [user]);

  const courses = [
    { path: '/bootcamp', id: 1, name: 'Front End Web Development', progress: 75 },
  ];

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop: '100px' }}>Enrolled</h1>
      <ul>
        {isEnrolled && courses.map(course => (
          <li key={course.id} style={{ listStyleType: 'none' }}>
            <Link to={course.path}>
              <EnrolledCourse name={course.name} progress={course.progress} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Enrolled;
