import React from 'react';

const EnrolledCourse = () => {
  return (
    <div className="bg-purple-50 rounded-lg shadow-md p-4 max-w-sm mx-auto">
      <div className="flex justify-center mb-4">
        {/* Icon */}
        <div className="bg-purple-100 rounded-full p-3">
          <svg className="h-12 w-12 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h5l7-7m0 0h5v5m-5-5L5 15m0 0v5h5M5 15l7 7m5-5h5v-5m-5 5l-7-7" />
          </svg>
        </div>
      </div>
      <div className="text-center">
        <h2 className="text-xl font-semibold text-gray-800 mb-2">Web Development Bootcamp</h2>
        <button className="bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-800 transition duration-200">
          Continue course
        </button>
      </div>
    </div>
  );
};

export default EnrolledCourse;
