// src/pages/sat/DynamicSectionPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// identified with /sat/ and then the sectionId
const DynamicSectionPage = () => {
  const { sectionId } = useParams();
  const [SectionComponent, setSectionComponent] = useState(null);

  useEffect(() => {
    const fetchSectionComponent = async () => {
      try {
        const sectionModule = await import(`./data/sections/Section${sectionId}`);
        setSectionComponent(() => sectionModule.default);
      } catch (error) {
        console.error("Could not load section component", error);
      }
    };

    fetchSectionComponent();
  }, [sectionId]);

  return (
    <div>
      {SectionComponent ? <SectionComponent /> : <div>Section not found</div>}
    </div>
  );
};

export default DynamicSectionPage;