// src/coursecomponents/ContentCard.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Adjust the import path as necessary
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const ContentCard = ({ title, description, link, userId, onCompletionToggle }) => {
  const transformedLink = link.replace(/\//g, '_');
  const [isCompleted, setIsCompleted] = useState(false);
  const [progress, setProgress] = useState(null);


  //this fetches from the firestore database 
  useEffect(() => {
    const fetchProgress = async () => {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const lessonProgress = userData.webBootcampProgress.lessons[transformedLink]?.completed || false;
        setIsCompleted(lessonProgress);
        setProgress(userData.webBootcampProgress);
      } else {
        console.log("User document does not exist.");
      }
    };

    fetchProgress();
  }, [userId, transformedLink]);

  //this function toggles the completion status of the lesson

  const toggleCompletion = async () => {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const currentStatus = userData.webBootcampProgress.lessons[transformedLink]?.completed || false;
      const updatedStatus = !currentStatus; // Toggle the completion status

      // Update the completion status in Firebase
      await updateDoc(userDocRef, {
        [`webBootcampProgress.lessons.${transformedLink}.completed`]: updatedStatus,
      });

      // Update local state
      setIsCompleted(updatedStatus);
      setProgress({
        ...progress,
        lessons: {
          ...progress.lessons,
          [transformedLink]: {
            ...progress.lessons[transformedLink],
            completed: updatedStatus,
          },
        },
      });

      // Notify parent component
      onCompletionToggle(title, updatedStatus);

      console.log(`Lesson ${transformedLink} completion status updated to ${updatedStatus}`);
    } else {
      console.log("User document does not exist.");
    }
  };

  return (
    <Link to={link}> 
<div className={`bg-white shadow-lg rounded-lg overflow-hidden m-4 ${isCompleted ? 'border-green-200 border-2' : ''}`}>      <div className="p-4">
        <h2 className="text-xl font-semibold text-gray-800 mb-2">{title}</h2>
        <p className="text-gray-600 mb-4">{description}</p>
        
        {/* this is the start button. */}
        <div className="flex justify-between items-center">
  <Link 
    to={link} 
    className={`bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200 ${isCompleted ? 'bg-green-500' : 'bg-blue-500'}`}
  >
    {isCompleted ? 'Review' : 'Start'}
  </Link>
</div>
      </div>
    </div>
    </Link>
  );
};

export default ContentCard;