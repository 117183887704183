import React, { useState } from 'react';
import { auth, db, app } from '../firebase'; // Import `db` from your firebase config
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore'; // Import `doc` and `getDoc` from Firestore



import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from "firebase/auth";
// Ensure you have configured Firebase

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();

  const handleSignIn = async (e) => {

    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/sat'); 
  
      // Redirect to another page or show success message
    } catch (error) {
      setError(error.message);
      console.log(error)
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      // Redirect to another page or show success message
    } catch (error) {
      setError(error.message);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      await signInWithPopup(auth, facebookProvider);
      // Redirect to another page or show success message
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-50">
      <form className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full" onSubmit={handleSignIn}>
        <h2 className="text-2xl font-bold mb-6 text-center">Log in to your Avantdemy account</h2>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <div className="mb-4">
          <label className="block text-gray-700 mb-1">Email</label>
          <input 
            type="email" 
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-1">Password</label>
          <input 
            type="password" 
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-purple-600 text-white py-2 rounded-lg hover:bg-purple-700 transition duration-300">
          Log in
        </button>
        <div className="text-center mt-4">
          <a href="#" className="text-purple-600 hover:underline">Forgot Password</a>
        </div>
        <div className="text-center mt-4 text-gray-500">Other log in options</div>
        <div className="flex justify-center mt-4">
          <button 
            type="button" 
            className="flex items-center justify-center w-10 h-10 mx-2 bg-gray-100 rounded-full hover:bg-gray-200" 
            onClick={handleGoogleSignIn}
          >
            <img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="Google" className="w-6 h-6"/>
          </button>
          <button 
            type="button" 
            className="flex items-center justify-center w-10 h-10 mx-2 bg-gray-100 rounded-full hover:bg-gray-200" 
            onClick={handleFacebookSignIn}
          >
            <img src="https://img.icons8.com/color/48/000000/facebook.png" alt="Facebook" className="w-6 h-6"/>
          </button>
          <button 
            type="button" 
            className="flex items-center justify-center w-10 h-10 mx-2 bg-gray-100 rounded-full hover:bg-gray-200"
          >
            <img src="https://img.icons8.com/ios-filled/50/000000/mac-os.png" alt="Apple" className="w-6 h-6"/>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignIn;