import { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import path as necessary
import { useAuth } from './useAuth';

const useStreak = () => {
  const [streak, setStreak] = useState(0);
  const { user } = useAuth();
  const userId = user ? user.uid : null;

  useEffect(() => {
    const fetchStreak = async () => {
      if (!userId) return;

      const userDocRef = doc(db, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) return;

      const userData = userDocSnap.data();
      const lastCompleted = userData.lastCompleted;
      const currentStreak = userData.streak || 0;

      if (!lastCompleted) {
        setStreak(0);
        return;
      }

      const now = new Date();
      const lastCompletedDate = new Date(lastCompleted.seconds * 1000);
      const diffTime = Math.abs(now.getTime() - lastCompletedDate.getTime());
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

      if (diffHours <= 24) {
        setStreak(currentStreak);
      } else {
        setStreak(0);
      }
    };

    fetchStreak();
  }, [userId]);

  const updateStreak = async () => {
    if (!userId) return;

    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) return;

    const userData = userDocSnap.data();
    const currentStreak = userData.streak || 0;
    const newStreak = currentStreak + 1;

    await setDoc(userDocRef, {
      ...userData,
      streak: newStreak,
      lastCompleted: new Date()
    });

    setStreak(newStreak);
  };

  return { streak, updateStreak };
};

export default useStreak;