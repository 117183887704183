import React from 'react';
import '../styles/tailwind.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import useStreak from '../hooks/useStreak'; // Import the useStreak hook

const NewHeader = () => {
    const { isLoggedIn, user } = useAuth();
    const { streak } = useStreak(); // Use the useStreak hook

    const handleSignOut = () => {
        signOut(auth);
    };

    return (
        <header className="py-4 md:py-4 border-b border-gray-300">
            <div className="container px-4 mx-auto sm:px-6 lg:px-8">
                <div className="flex items-center justify-between">
                    <div className="flex-shrink-0">
                        <Link to="/" title="" className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                            <div className="flex items-center">
                                <img src="/logo8.png" alt="Logo" className="h-8 w-8 mr-2" />
                                <h1 className="text-3xl font-bold text-black font-inter">Avantdemy</h1>
                            </div>
                        </Link>
                    </div>

                    <div className="hidden lg:flex lg:ml-10 xl:ml-16 lg:items-center lg:justify-center lg:space-x-8 xl:space-x-16">
                        
                        {isLoggedIn && (
                          <>
                            <Link to="/sat" title="" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> Home </Link>
                            <Link to="/leaderboards" title="" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> Leaderboards </Link>
                          </>
                        )}
                    </div>

                    <div className="hidden lg:ml-auto lg:flex lg:items-center lg:space-x-8 xl:space-x-10">
                        {isLoggedIn ? (
                            <>
                                <div className="flex items-center">
                                    <span className="mr-2">🔥 {streak}</span> {/* Streak display */}
                                    <Link title="" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2" onClick={handleSignOut}> Logout </Link>
                                </div>
                            </>
                        ) : (
                            <>
                                <Link to="/signin" title="" className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"> Sign in </Link>
                                <Link to="/signup" title="" className="px-5 py-2 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900" role="button">
                                    Create free account
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default NewHeader;