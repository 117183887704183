import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { UserProgressProvider } from './contexts/UserProgressContext';
import SignUp from './auth/SignUp';
import SignIn from './auth/SignIn';
import AboutBootcamp from './pages/home/AboutBootcamp';
import BuyScreen from './pages/buy/BuyScreen';
import Enrolled from './pages/enrolled/Enrolled';
import BootcampHome from './pages/webbootcamp/BootcampHome';
import ProtectedRoute from './components/ProtectedRoute';
import DynamicPage from './webdevbootcontent/DynamicPage';
import NewHeader from './components/NewHeader';
import Learn from './coursecomponents/Learn';
import Library from './pages/Library';
import SatHome from './pages/sat/SatHome';
import DynamicSectionPage from './pages/sat/DynamicSectionPage';
import DynamicLessonPage from './pages/sat/content/DynamicLessonPage';
import AboutSat from './pages/sat/AboutSat';
import LeaderboardHome from './pages/leaderboards/LeaderboardHome'; // Import LeaderboardHome component

const AppContent = () => {
  const location = useLocation();
  const hideHeaderPath = /^\/sat\/[^/]+\/[^/]+$/;

  return (
    <>
      {!hideHeaderPath.test(location.pathname) && <NewHeader />}
      <Routes>
        <Route path="/sat" element={<SatHome />} />
        <Route path="/library" element={<Library />} />
        <Route path="/learn" element={<Learn />} />
        <Route path="/learnbootcamp" element={<AboutBootcamp />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/enrolled" element={<ProtectedRoute><Enrolled /></ProtectedRoute>} />
        <Route path="/buy" element={<ProtectedRoute><BuyScreen /></ProtectedRoute>} />
        <Route path="/bootcamp" element={<ProtectedRoute><BootcampHome /></ProtectedRoute>} />
        <Route path="/:chapterName/:lessonName" element={<ProtectedRoute><DynamicPage /></ProtectedRoute>} />
        <Route path="/sat/:sectionId" element={<ProtectedRoute><DynamicSectionPage /></ProtectedRoute>} />
        <Route path="/sat/:sectionId/:lessonId" element={<ProtectedRoute><DynamicLessonPage /></ProtectedRoute>} />
        <Route path="/leaderboards" element={<LeaderboardHome />} /> {/* New route for LeaderboardHome */}
        <Route path="/" element={<AboutSat />} /> {/* Default route to AboutSat */}
      </Routes>
    </>
  );
};

const App = () => (
  <Router>
    <UserProgressProvider>
      <AppContent />
    </UserProgressProvider>
  </Router>
);

export default App;