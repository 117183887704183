import React from 'react';

import Advantage from './Advantage';

import LandingPage from '../../components/LandingPage';




import Footer from '../../components/Footer';

import Resume from './Resume';




export default function AboutBootcamp() {
  return (
    
    <>
        <LandingPage/>
    <Resume/>
    <Advantage/>
  


    <Footer/>


    


    </>
    
       
  );
}


