import React, { useState, useEffect } from "react";

const ProductDisplay = () => (
  <section>
    <div className="product">
    <img
  src="https://i.imgur.com/EHyR2nP.png"
  alt="The cover of Stubborn Attachments"
  style={{
    width: '100%', // Makes the image responsive by setting its width to 100% of the parent element
    height: 'auto', // Keeps the image's aspect ratio by setting its height to auto
    borderRadius: '10px', // Adds rounded corners with a radius of 10px
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Adds a subtle shadow around the image
  }}
/>
      <div className="description">
      <h3>Avantdemy Web Development Bootcamp</h3>
      <h5>$250.00</h5>
      </div>
    </div>
    <form action="/create-checkout-session" method="POST">
      <button type="submit">
        Checkout
      </button>
    </form>
  </section>
);

export default ProductDisplay;