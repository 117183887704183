import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../../hooks/useAuth';
import { useUserProgress } from '../../../contexts/UserProgressContext';




export const useEnhancedCourseData = () => {
  const { user } = useAuth();
  const userId = user ? user.uid : null;
  const { userProgress, setUserProgress } = useUserProgress();
  const [enhancedCourseData, setEnhancedCourseData] = useState([]);

  const constantCourseData = [
    //section 1 
    {
      id: 0,
      link:'/sat/1',
      title: "Section 1",
      description: 'markup, attributes, tags, and more...',
      numLessons: 4,
      lectures: [
        { link: '/sat/1/L1', title: 'solving linear equalities', description: '10xp' },
        { link: '/sat/1/L2', title: ' Solving Linear Inequalities', description: 'Solving Linear Inequalities' },
        { link: '/sat/1/L3', title: 'Linear equation word problems', description: 'the most intro intro' },
        { link: '/sat/1/L4', title: 'Solving linear relationship word problems', description: 'the most intro intro' },
        { link: '/sat/1/L5', title: 'Graphs', description: 'the most intro intro' },
        { link: '/sat/1/L6', title: 'solving systems of linear equations', description: 'the most intro intro' },
          { link: '/sat/1/L7', title: 'Systems of linear equations word problems', description: 'the most intro intro' },
            { link: '/sat/1/L8', title: ' linear inequality word problems ', description: 'the most intro intro' },
              { link: '/sat/1/L9', title: 'graphs of linear equations and functions ', description: 'the most intro intro' },
       
      ],
      //lessons object/dictionary will be added to each section 
    },


    //section 2 
    {
        id: 1,
      link:'/sat/2',
      title: "Section 2",
      description: '30 min',
      numLessons: 2,
      lectures: [
        { link: '/html/P5', title: 'basic HTML tags', description: 'the most intro intro' },
        { link: '/html/P6', title: 'creating lists', description: 'the most intro intro' },
      ]
    },
    {
        id: 2,
        link:'/sat/3',
      title: "Section 3",
      description: '30 min',
      numLessons: 2,
      lectures: [
        { link: '/html/P5', title: 'basic HTML tags', description: 'the most intro intro' },
        { link: '/html/P6', title: 'creating lists', description: 'the most intro intro' },
      ]
    },
  ];

  useEffect(() => {
    const initializeWebDevBootcampForUser = async (userId) => {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists() && !userDocSnap.data().webBootcampInitialized) {
        const dynamicData = {
          lessons: {
          '_sat_1_L1': { completed: false },
'_sat_1_L2': { completed: false },
'_sat_1_L3': { completed: false },
'_sat_1_L4': { completed: false },
'_sat_1_L5': { completed: false },

         
          }
        };
        //will update for the sat data 
        await updateDoc(userDocRef, {
          sat: dynamicData,
          webBootcampInitialized: true
        });

        console.log("Web Development Bootcamp data initialized for user:", userId);
      } else {
        console.log("Web Development Bootcamp data already initialized or user document does not exist.");
      }
    };

    if (userId) {
      initializeWebDevBootcampForUser(userId);
    }
  }, [userId]);

  useEffect(() => {
    const fetchUserProgress = async () => {
      if (user) {
        const userDocRef = doc(db, "users", userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          if (userData.sat) {
            setUserProgress(userData.sat);
          } else {
            console.log("No sat found for this user.");
          }
        } else {
          console.log("User document does not exist.");
        }
      }
    };

    fetchUserProgress();
  }, [user, setUserProgress, userId]);

  useEffect(() => {
    if (userProgress) {
      const enhancedData = constantCourseData.map(section => {
        const lectures = section.lectures.map(lecture => {
          const lectureKey = lecture.link.replace(/\//g, '_');
          const completed = userProgress.lessons[lectureKey]?.completed || false;
          return { ...lecture, completed };
        });
        return { ...section, lectures };
      });

      setEnhancedCourseData(enhancedData);
    }
  }, [userProgress]);

  return enhancedCourseData;
};

//in this array how do i access the first section object and access the lectures array 
