import React, {memo} from "react";
import {useEnhancedCourseData} from '../sat/data/data'
import { useAuth } from "../../hooks/useAuth";
import Dropdown from "../../coursecomponents/Dropdown";
import Section from './components/Section'

import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the import path as necessary

import {useState, useEffect} from 'react'
import Sidebar from "./components/Sidebar";
import Leaderboard from '../../components/Leaderboard';
import useStreak from '../../hooks/useStreak'; // Import the useStreak hook




const StreakComponent = () => {
  const [xp, setXp] = useState(0);
  const { user } = useAuth(); // Use the useAuth hook to get the current user
  const userId = user ? user.uid : null; // Access the uid property from the user object
  const { streak } = useStreak(); // Use the useStreak hook

  useEffect(() => {
    const fetchXp = async () => {
      if (userId) {
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setXp(userData.xp || 0); // Set XP from the user data
        } else {
          console.log("User document does not exist.");
        }
      }
    };

    fetchXp();
  }, [userId]);

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="text-4xl font-bold">{streak} 🔥</div> {/* Display streak */}
      <p className="text-gray-600">Complete a Lesson to Start a Streak</p>
     
      <div className="flex justify-between mt-4">
        <div className="text-gray-600">1 Longest streak</div>
        <div className="text-gray-600">1 Lessons completed</div>
      </div>
      <div className="mt-4 p-2 bg-yellow-100 text-yellow-700 rounded-lg">
        <p className="text-sm">UNLOCK LEADERBOARDS</p>
        <p className="text-xl font-bold">{xp} of 50 XP</p> {/* Keep XP display */}
      </div>
    </div>
  );
};

const SatHome = () => {
  const enhancedCourseData1 = useEnhancedCourseData();
  const { user } = useAuth();
  const userId = user ? user.uid : null;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      <div className="flex-1 overflow-auto">
        <div className="p-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Left Column */}
            <div>
             {enhancedCourseData1.map((sectionData) => (
                sectionData.lectures && (
                  <Section
                    key={sectionData.title}
                    link={sectionData.link}
                    title={sectionData.title}
                    description={sectionData.description}
                    lectures={sectionData.lectures}
                    userId={userId}
                  />
                )
              ))}
            </div>

            {/* Right Column */}
            <div>
               <h1 className="text-2xl font-bold mb-4">Jump Back In.</h1>
              <StreakComponent />
              <Leaderboard/>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SatHome;