import React, { useState } from 'react';
import InfoCard from './tools/InfoCard';
import InfoCardReverse from './tools/InfoCardReverse';

const Advantage = () => {
  const [activeTab, setActiveTab] = useState('one'); // activeTab variable stores the name

  const renderContent = () => { // Depending on the name of the tab, it will render the content
    switch (activeTab) {
      case 'one':
        return (
          
          <div>
           <h2 className="text-2xl font-bold mb-2">15 minutes a day keeps Unemployment Away</h2>
            <p>Many learners run into procrastination, can’t find time, or lack confidence after completing courses. <br/>To address this, We designed this platform to make 
            learning easy and fun as possible to make<br/> web development an everyday habit.</p>
          


          <InfoCard 
            title="hands on learning" 
            description="We get you ready for real work just like professionals in the industry, no more babying." 
            image="/code.png" 
          />
           <InfoCardReverse
            title="bite sized learning" 
            description="Master Web Development with our interactive, compact, and fun lessons that only take 15 minutes per day,
            in 6 months to be job ready" 
            image="/code.png" 
          />
          <InfoCard 
            title="stay motivated" 
            description="Learning can be hard sometimes. Which is why we partnered not only technical pros but also researchers 
            to embed game like features at the core of our platform to keep you motivated while maintaining professionalism." 
            image="/code.png" 
          />

          </div>
        );
      case 'job':
        return (
          <div>
            <h2 className="text-xl font-bold mb-2">Real Work Experience</h2>
            <p>We offer a job guarantee to ensure you find a job in tech after completing our program.</p>
            <InfoCard 
            title="Build your Portfolio by building for Real Clients. " 
            description="Gain guaranteed real-world experience by building websites for real businesses and entities. You will be placed in a max size group of 5 and whoever has the best website moves up in the arenas and gets paid the most.

Learn more 
" 
            image="/cookie.png" 
          />
            <InfoCardReverse
            title="We partner with the best." 
            description="Complete real jobs for universities, medium-small businesses, non profits, and startups at the comfort of your home." 
            image="/employer.png" 
          />
            <InfoCard 
            title="Jobs straight to inbox monthly" 
            description="Meet deliverables for clients, collect money, climb higher in arenas and earn more. 
Pay varies between $15 to $1000 per job.
" 
            image="/code.png" 
          />
         
         
          </div>
        );
      case 'process':
        return (
          <div>
            <h2 className="text-xl font-bold mb-2">Curriculum Created by developers for developers</h2>
            <p>Our process is designed to be simple and effective, ensuring you get the most out of our program.</p>
           
            <InfoCard 
            title="Crowd Sourced Curriculum " 
            description="Over 100 experts from Google to Harvard have contributed to our mission to provide top-notch education. Our lessons include their key insights and secrets to help you confidently break into web development. Deeper understanding fix"
            image="/cookie.png" 
          />
          </div>
        );
      case 'support':
        return (
          <div>
            <h2 className="text-xl font-bold mb-2">Support</h2>
            <p>We offer comprehensive support to help you succeed in your new career.</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="p-4 mx-48">
     {/* Tab section */}
      <div className="flex justify-center items-center space-x-4 bg-white p-4">
        <button
          className={`px-4 py-2 rounded ${
            activeTab === 'one' ? 'bg-black text-white ' : 'bg-transparent text-black'
          }`}
          onClick={() => setActiveTab('one')}
        >
          Learn
        </button>
        <button
          className={`px-4 py-2 rounded ${
            activeTab === 'job' ? 'bg-black text-white' : 'bg-transparent text-black '
          }`}
          onClick={() => setActiveTab('job')}
        >
          Work
        </button>
        <button
          className={`px-4 py-2 rounded ${
            activeTab === 'process' ? 'bg-black text-white' : 'bg-transparent text-black '
          }`}
          onClick={() => setActiveTab('process')}
        >
          Curriculum
        </button>
        <button
          className={`px-4 py-2 rounded ${
            activeTab === 'support' ? 'bg-black text-white' : 'bg-transparent text-black border border-black'
          }`}
          onClick={() => setActiveTab('support')}
        >
          4U.
        </button>
      </div>

      {/* Content section */}
      <div className="mt-4">
        {renderContent()}
      </div>
    </div>
  );
};

export default Advantage;